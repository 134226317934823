// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-avis-js": () => import("./../../../src/pages/avis.js" /* webpackChunkName: "component---src-pages-avis-js" */),
  "component---src-pages-fb-js": () => import("./../../../src/pages/fb.js" /* webpackChunkName: "component---src-pages-fb-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-prestations-js": () => import("./../../../src/pages/prestations.js" /* webpackChunkName: "component---src-pages-prestations-js" */),
  "component---src-pages-works-js": () => import("./../../../src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */)
}

